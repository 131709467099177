import { mapActions, mapGetters, mapState } from "vuex";
import PlotUtils from "@/core/utils/plot.utils";
import moment from "moment";
import dataManagementMixin from "@/core/mixins/dataManagement.mixin";
import rasterLayerMixin from "@/core/mixins/rasterLayer.mixin";
import axios from "axios";

let virtualStationMixin = {
  props: ["station"],
  data: () => ({
    addButtonClicked: false,
    actualValue: {}
  }),
  mixins: [dataManagementMixin, rasterLayerMixin],
  computed: {
    ...mapState("plot", ["selectedStation"]),
    ...mapState("coastsPlot", ["profileLayer"]),
    ...mapState("management", [
      "accessToken",
      "tempResolution",
      "aggregationStep"
    ]),
    ...mapState("raster", [
      "activeRasterLayer",
      "layerTimesteps",
      "tempResLayerTimesteps",
      "filteredDatetimeSelection"
    ]),
    ...mapGetters("plot", ["disabledPlotButton", "dataCachePlotIds"]),
    ...mapGetters("management", ["activeRegion"]),
    plotStation() {
      if (this.station) {
        return this.station;
      } else {
        return this.selectedStation;
      }
    },
    addButtonDisabled: {
      get() {
        return this.addButtonClicked && !this.disabledPlotButton;
      },
      set() {
        this.addButtonClicked = true;
      }
    },
    displayActualValue() {
      const unit = this.activeRasterLayer.unit
        ? this.activeRasterLayer.unit
        : "";
      const bandsFormatted = Object.keys(this.actualValue).map(band => {
        const value = this.actualValue[band];

        return value !== null && !isNaN(value)
          ? `<b>${value} ${unit}</b>`
          : `<b>${this.$t("noData")}</b>`;
      });
      return bandsFormatted.length ? bandsFormatted.join(", ") : "|";
    },
    plotId() {
      return `${this.activeRasterLayer.layerId}_${this.plotStation.name}_${this.tempResolution}_${this.aggregationStep}`;
    }
  },
  methods: {
    ...mapActions("plot", [
      "addDataEntry",
      "setShowPlotFeature",
      "setShowPlot"
    ]),
    async setDateRange(activeRasterLayer = this.activeRasterLayer, layerSteps) {
      let first_step = "";
      let last_step = "";
      if (layerSteps?.length) {
        const length = layerSteps.length;
        first_step = layerSteps[length - 1].datetime;
        last_step = layerSteps[0].datetime;
      } else {
        const stepRange = await this.fetchLayerStepRange(
          activeRasterLayer.layerId
        );
        first_step = stepRange.first_step;
        last_step = stepRange.last_step;
      }
      return { first_step: first_step, last_step: last_step };
    },
    async fetchTimeseries(
      activeRasterLayer = this.activeRasterLayer,
      station = this.plotStation,
      layerSteps = this.layerTimesteps,
      sD,
      eD
    ) {
      const stepRange = await this.setDateRange(activeRasterLayer, layerSteps);
      let daysBack = 500;
      let endDate = moment(stepRange.last_step).add(1, "d");
      let startDate = moment(endDate).subtract(daysBack, "d");

      let datetime = [];
      let values = [];
      const isEndDateValid = endDate.isValid();
      if (moment(startDate) < moment(stepRange.first_step)) {
        if (sD && eD) {
          let response = await this.$rastless.post(
            `/layers/${activeRasterLayer.layerId}/statistic?start_date=${sD}&end_date=${eD}&token=${this.accessToken}`,
            {
              geometry: this.virtualStation.geometry
            }
          );
          datetime = datetime.concat(response.data.datetime);
          values = values.concat(Object.values(response.data.data)[0]);
        } else {
          let response = await this.$rastless.post(
            `/layers/${activeRasterLayer.layerId}/statistic?token=${this.accessToken}`,
            {
              geometry: station.geometry
            }
          );
          datetime = datetime.concat(response.data.datetime);
          values = values.concat(Object.values(response.data.data)[0]);
        }
      } else {
        let requests = [];
        requests.push(
          this.$rastless
            .post(
              `/layers/${
                activeRasterLayer.layerId
              }/statistic?start_date=${startDate.format(
                "YYYY-MM-DD"
              )}&end_date=${endDate.format("YYYY-MM-DD")}&token=${
                this.accessToken
              }`,
              {
                geometry: station.geometry
              }
            )
            .then(async response => {
              datetime = datetime.concat(response.data.datetime);
              values = values.concat(Object.values(response.data.data)[0]);
            })
        );
        while (moment(startDate) >= moment(stepRange.first_step)) {
          endDate = startDate;
          startDate = moment(endDate).subtract(500, "d");
          if (isEndDateValid === true) {
            requests.push(
              this.$rastless
                .post(
                  `/layers/${
                    activeRasterLayer.layerId
                  }/statistic?start_date=${startDate.format(
                    "YYYY-MM-DD"
                  )}&end_date=${endDate.format("YYYY-MM-DD")}&token=${
                    this.accessToken
                  }`,
                  {
                    geometry: station.geometry
                  }
                )
                .then(async response => {
                  if (Object.values(response.data.data)[0]) {
                    datetime = datetime.concat(response.data.datetime);
                    values = values.concat(
                      Object.values(response.data.data)[0]
                    );
                  }
                })
            );
          }
        }
        await Promise.all(requests);
      }

      let datetimeSelection = { datetime: [], data: { B1: [] } };
      var date = {};
      for (var i = 0, n = datetime.length; i < n; i++) {
        date[datetime[i]] = values[i];
      }
      const keys = Object.keys(date).sort();
      for (var key in keys) {
        var prop = keys[key];
        datetimeSelection.datetime.push(prop);
        datetimeSelection.data.B1.push(date[prop]);
      }
      return datetimeSelection;
    },

    filterTimeseries(data, date = this.activeRasterLayer.datetime) {
      let filteredTimeseries = [];
      if (this.tempResolution == "daily") {
        data.datetime.forEach((datetime, index) => {
          if (datetime.split("T")[0] == date) {
            filteredTimeseries.push(data.data.B1[index]);
          }
        });
      } else if (this.tempResolution == "monthly") {
        data.datetime.forEach((datetime, index) => {
          const str = datetime.split("-");
          if (str[0] + "-" + str[1] == date) {
            filteredTimeseries.push(data.data.B1[index]);
          }
        });
      } else {
        data.datetime.forEach((datetime, index) => {
          let start = moment(date, "YYYY-MM-DD").subtract(
            this.aggregationStep,
            "days"
          );
          let end = moment(date, "YYYY-MM-DD").add(
            this.aggregationStep,
            "days"
          );
          if (
            start <= moment(datetime.split("T")[0], "YYYY-MM-DD") &&
            moment(datetime.split("T")[0], "YYYY-MM-DD") <= end
          ) {
            filteredTimeseries.push(data.data.B1[index]);
          }
        });
      }
      return filteredTimeseries;
    },
    calculateMedian(arr) {
      const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
      return (arr.length % 2 !== 0
        ? nums[mid]
        : (nums[mid - 1] + nums[mid]) / 2
      ).toFixed(2);
    },
    aggregateTimeseries(data) {
      let datetimeSelection = [];
      let dataSelection = [];
      this.tempResLayerTimesteps.forEach(timestep => {
        const dataSeries = this.filterTimeseries(data, timestep);
        if (dataSeries.length >= 1) {
          datetimeSelection.push(timestep);
          dataSelection.push(this.calculateMedian(dataSeries));
        }
      });
      return { datetime: datetimeSelection, data: { B1: dataSelection } };
    },
    aggregateManualTimeseries(data) {
      let datetimeSelection = [];
      let dataSelection = [];
      this.tempResLayerTimesteps.forEach(timerange => {
        let rangeSelection = [];
        const range = {
          startDate: timerange.split(" - ")[0],
          endDate: timerange.split(" - ")[1]
        };
        const filteredSeries = data.datetime.filter(
          step =>
            moment(step) >= moment(range.startDate) &&
            moment(step) <= moment(range.endDate)
        );
        if (filteredSeries.length >= 1) {
          filteredSeries.forEach(timestep => {
            data.datetime.forEach((datetime, index) => {
              if (timestep == datetime) {
                rangeSelection.push(data.data.B1[index]);
              }
            });
          });
        }
        let middle = moment(timerange.split(" - ")[0], "YYYY-MM-DD");
        middle.add(this.aggregationStep / 2, "days");
        datetimeSelection.push(middle.format("YYYY-MM-DD"));
        dataSelection.push(this.calculateMedian(rangeSelection));
      });
      return { datetime: datetimeSelection, data: { B1: dataSelection } };
    },

    async addTimeseriesToPlot(activeRasterLayer = this.activeRasterLayer) {
      if (this.plotStation?.geometry.type !== "LineString") {
        this.setShowPlot(true);
      }
      this.setShowPlotFeature(true);
      this.dataLoading = true;
      let data = await this.fetchTimeseries(activeRasterLayer);
      data = await this.filterOutMarkedSteps(
        data,
        this.activeRegion.id,
        activeRasterLayer.layerId,
        this.plotStation.id
      );
      this.addButtonDisabled = true;
      if (this.tempResolution == "daily" || this.tempResolution == "monthly") {
        data = this.aggregateTimeseries(data);
      } else if (this.tempResolution == "custom") {
        data = this.aggregateManualTimeseries(data);
      }
      let datetimeObjects = [];
      let datetimeArray = [];
      let valuesArray = [];
      for (const [band, values] of Object.entries(data.data)) {
        data.datetime.forEach((timestep, index) => {
          if (this.filteredDatetimeSelection.length) {
            let datetimeObjectSingle = this.filteredDatetimeSelection.filter(
              step => {
                if (step?.datetime) {
                  return step.datetime === timestep;
                } else {
                  return step == timestep;
                }
              }
            );
            if (datetimeObjectSingle.length) {
              datetimeArray.push(data.datetime[index]);
              valuesArray.push(values[index]);
              datetimeObjects.push(datetimeObjectSingle[0]);
            }
          } else {
            datetimeArray.push(data.datetime[index]);
            valuesArray.push(values[index]);
          }
        });
        console.log(band);
        const legendName = PlotUtils.createVirtualStationLegendName({
          layerName: activeRasterLayer.title,
          stationName: this.plotStation.name,
          coordinates: this.coordinates,
          unit: activeRasterLayer.unit,
          tempRes: this.tempResolution,
          aggregationStep: this.aggregationStep
        });
        const filteredValues = PlotUtils.filterNullValues(
          datetimeArray,
          valuesArray,
          datetimeObjects
        );

        const plotData = PlotUtils.createPlotEntry({
          layerId: activeRasterLayer.layerId,
          layerType: "raster",
          x: filteredValues.x,
          y: filteredValues.y,
          steps: filteredValues.steps,
          legendName: legendName,
          unit: activeRasterLayer.unit,
          plotId: this.plotId
        });
        if (!this.dataCachePlotIds.includes(this.plotId)) {
          this.addDataEntry(plotData);
        }
      }

      this.dataLoading = false;
      return this.dataLoading;
    },
    defineDateRange() {
      if (this.tempResolution == "daily") {
        return {
          startDate: `${this.activeRasterLayer.datetime}T00:00:00`,
          endDate: `${this.activeRasterLayer.datetime}T23:59:59`
        };
      } else if (this.tempResolution == "monthly") {
        return {
          startDate: `${this.activeRasterLayer.datetime}-01T00:00:00`,
          endDate: `${this.activeRasterLayer.datetime}-31T23:59:59`
        };
      } else if (this.tempResolution == "custom") {
        const startDate = moment(this.activeRasterLayer.datetime)
          .subtract(this.aggregationStep, "d")
          .format("YYYY-MM-DD");
        return {
          startDate: `${startDate}-01T00:00:00`,
          endDate: `${this.activeRasterLayer.datetime}-31T23:59:59`
        };
      }
    },
    async fetchActualValue() {
      if (
        !this.activeRasterLayer ||
        this.plotStation.region !== this.activeRegion.id
      ) {
        return;
      }
      const geometry = this.plotStation.geometry;
      if (this.$appConfig.keycloakClient === "coasts") {
        const response = await this.$rastless.post(
          `/layers/${this.profileLayer.layerId}/${this.profileLayer.datetime}/statistic?token=${this.accessToken}`,
          {
            statistic: "median",
            geometry: geometry
          }
        );
        this.actualValue = response.data.data;
      } else {
        if (this.activeRasterLayer?.sensor) {
          const response = await this.$rastless.post(
            `/layers/${this.activeRasterLayer.layerId}/${this.activeRasterLayer.datetime}/statistic?token=${this.accessToken}`,
            {
              statistic: "median",
              geometry: geometry
            }
          );
          this.actualValue = response.data.data;
          this.actualValueLoading = false;
        } else {
          const dates = this.defineDateRange();
          if (dates !== null) {
            const response = await this.$rastless.post(
              `layers/${this.activeRasterLayer.layerId}/statistic?start_date=${dates.startDate}&end_date=${dates.endDate}&token=${this.accessToken}`,
              {
                statistic: "median",
                geometry: geometry
              }
            );
            if (response.data?.datetime.length) {
              this.actualValue = {
                B1: this.calculateMedian(Object.values(response.data.data)[0])
              };
            } else {
              this.actualValue = 0;
            }
            this.actualValueLoading = false;
          } else {
            this.fetchTimeseries().then(data => {
              this.actualValue = {
                B1: this.calculateMedian(this.filterTimeseries(data))
              };
              this.actualValueLoading = false;
            });
          }
        }
      }
    },
    async fetchVirtualStationsByRegion(regionId) {
      const response = await axios.get(
        `userdata/virtual-stations/?region=${regionId}`
      );
      return response.data;
    }
  },
  watch: {
    activeRasterLayer: {
      handler() {
        // TODO
        //if (this.activeRasterLayer?.layerId && this.plotStation?.geometry.type !== "LineString" && this.plotStation !== null) {
        if (
          this.plotStation?.geometry.type !== "LineString" &&
          this.plotStation !== null
        ) {
          this.addButtonClicked = this.dataCachePlotIds.includes(this.plotId);
          this.fetchActualValue();
        }
      },
      immediate: true
    },
    tempResLayerTimesteps: {
      handler() {
        if (
          this.plotStation?.geometry.type !== "LineString" &&
          this.plotStation !== null
        ) {
          this.addButtonClicked = this.dataCachePlotIds.includes(this.plotId);
        }
      },
      immediate: true
    }
  }
};

export default virtualStationMixin;
